<template lang="html">
  <div>
    <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="changeCellLoading" />

    <div :class='["tab", mobileTabCell ? "mobile-tab-cell" : "web-tab", isCondensed ? "big-cells" : "normal-cells"]'
      v-show='isActive' v-if="selectedTabObj">
      <div v-if="isGrid || isCondensed" class="md-layout md-gutter md-alignment-left-left public-user-cells-container">
        <draggable v-model="selectedTabObj.tabCells" :options="{ draggable: '.editable' }"
          :class='{ "public-user-cells": publicUsername != null || adminMode, "public-user-cells-condensed": isCondensed}'
          @change="onMoveChange">
          <div class="cell-container-parent" :class='{
            "md-layout-item cell-it": (editableCell === true || editableCellMobile === true),
            "md-layout-item editable": (editableCell === false || editableCellMobile === false),
            "cell-margin-bottom-condensed": isCondensed, "cell-margin-bottom-grid": isGrid
          }' v-for="(cell, cellIndex) in selectedTabObj.tabCells" :key="cell.id" :id="'cell-' + cell.id"
            :position="cell.position" data-v-step="6">
            <div title="Edit site" class="cell-edit-on-hover" v-show="!isWeeklyTab && isCondensed && editableCell === true">
                <i class="far fa-edit" @click="showCellModal(cell)"></i>
            </div>
            <a class="cell-container-a-tag" :rel="cellRel" :href="editableCell ? !editableCellMobile ? 'javascript:;' : cell.link : 'javascript:;'"
              :title="linkOrAlt(cell)" :target="editableCell ? !editableCellMobile ? '' : '_blank' : ''">
              <div class="cell-container draggable edit-cell"
                :class="isCondensed ? 'cell-container-padding-condensed' : 'cell-container-padding-grid'"
                v-show="(editableCell === false || editableCellMobile === false) && !isWeeklyTab"
                v-if="!publicUsername || adminMode || !isDifferentUser">
                <i class="fas fa-arrows-alt"></i>
                <i class="far fa-edit" @click="showCellModal(cell)"></i>
              </div>
              <div class="cell-container-outer" :class="isCondensed ? 'cell-container-condensed' : 'cell-container-grid'">
                <div v-if="isWeeklyTab"
                  :class="(editableCell === false || editableCellMobile === false) ? 'cell-container draggable opacity-link' : 'cell-container draggable weekly-tab'">
                  <img :src="cell.image" :alt="linkOrAlt(cell)">
                </div>
                <div v-else
                  :class="(editableCell === false || editableCellMobile === false) ? 'cell-container draggable opacity-link' : 'cell-container draggable'"
                  :style="{ 'background-image': 'url(' + selectedTabObj.public_path + ')', 'background-position': '0 ' + -(cellIndex) * 25 + 'px', 'background-color': 'white' }">
                </div>
              </div>
            </a>
          </div>
          <div class="cell-x md-layout-item" slot="footer" title="Add new site"
            :class="isCondensed ? 'cell-margin-bottom-condensed' : 'cell-margin-bottom-grid'" style="padding-left: 0;"
            v-if="!publicUsername || adminMode || !isDifferentUser" @click="showCellModal(selectedTabObj)"
            v-show="(editableCell === true || editableCellMobile === true) && !isWeeklyTab" data-v-step="8">
            <div class="cell-container-outer" :class="isCondensed ? 'cell-container-condensed' : 'cell-container-grid'">
              <div class="cell-container ripple plus-icon-cell"
                :style="{ 'background-image': 'url(' + `${publicPath}plus-image-png.png` + ')', 'background-position': '2,5' + -(cellIndex) * 25 + 'px' }">
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div v-else class="md-layout md-gutter md-alignment-left-left">
        <draggable v-model="selectedTabObj.tabCells" :options="{ draggable: '.editable' }" style="display: inline-block;"
          :class='{ "public-user-cells": publicUsername != null || adminMode }' @change="onMoveChange">
          <div v-for="(cell, cellIndex) in selectedTabObj.tabCells"
            :class='{ "md-layout-item cell-it": (editableCell === true || editableCellMobile === true), "md-layout-item editable": (editableCell === false || editableCellMobile === false) }'
            class="list-container" :key="cell.id" :id="'cell-' + cell.id" :position="cell.position" data-v-step="6">
            <div>
              <a class="cell-container-a-tag" :rel="cellRel" :href="editableCell ? !editableCellMobile ? 'javascript:;' : cell.link : 'javascript:;'"
                :title="linkOrAlt(cell)" :target="editableCell ? !editableCellMobile ? '' : '_blank' : ''">
                <div class="cell-container draggable edit-cell"
                  :class="isCondensed ? 'cell-container-padding-condensed' : 'cell-container-padding-grid'"
                  v-show="(editableCell === false || editableCellMobile === false) && !isWeeklyTab"
                  v-if="!publicUsername || adminMode || !isDifferentUser">
                  <i class="fas fa-arrows-alt"></i>
                  <i class="far fa-edit" @click="showCellModal(cell)"></i>
                </div>
                <div class="logo">
                  <div v-if="isWeeklyTab"
                    :class="(editableCell === false || editableCellMobile === false) ? 'cell-container draggable opacity-link' : 'cell-container draggable weekly-tab'">
                    <img :src="cell.image" :alt="linkOrAlt(cell)">
                  </div>
                  <div v-else
                    :class="(editableCell === false || editableCellMobile === false) ? 'cell-container draggable opacity-link' : 'cell-container draggable'"
                    :style="{ 'background-image': 'url(' + selectedTabObj.public_path + ')', 'background-position': '0 ' + -(cellIndex) * 25 + 'px', 'background-color': 'white', }">
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a class="cell-title alt" :href="cell.link" target='_blank'>
                {{ linkOrAlt(cell) }}
              </a>
              <a :href="cell.link" target='_blank'>
                <div class="link alt" :title="cell.link">{{ cell.link }}</div>
              </a>
            </div>
          </div>
        </draggable>
      </div>
    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable';
import changeCellLoaderMixin from '@/mixins/changeCellLoaderMixin';
import cellLayoutMixin from '@/mixins/cellLayoutMixin';
import weeklyTabMixin from '@/mixins/weeklyTabMixin';
import {
  UPDATE_TAB_CELL_POSITION,
} from '../../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Tab',
  components: {
    draggable,
  },
  props: {
    hidden: {
      type: Boolean,
      default: true,
    },
    adminMode: Boolean,
    isDifferentUser: Boolean,
    mobileTabCell: Boolean,
    publicUsername: Object,
    selectedTabObj: Object,
    editableCellMobile: {
      type: Boolean,
      default: true,
    },
    currentCellsView: Number,
    editableCell: Boolean,
  },
  mixins: [changeCellLoaderMixin, cellLayoutMixin, weeklyTabMixin],
  computed: {
    cellRel() {
      if (this.isDifferentUser) {
        return this.$store.getters.userData?.follow_links ? 'tag' : 'nofollow';
      }
      return this.$store.getters.user?.follow_links ? 'tag' : 'nofollow';
    },
    linkOrAlt() {
      return cell => (cell.alt !== null && cell.alt !== '' ? cell.alt : cell.link);
    },
    selectedTabIndex() {
      return this.$store.getters.selectedTabIndex;
    },
  },
  data() {
    return {
      isActive: true,
      publicPath: process.env.BASE_URL,
      cellIndex: null,
    };
  },
  methods: {
    login() {
      this.$router.push({
        name: 'userLogin',
      });
    },
    onMoveChange(itemMoved) {
      // contains information of an element moved
      const payload = {
        id: itemMoved.moved.element.id,
        position: itemMoved.moved.newIndex + 1,
        adminMode: !!this.adminMode,
      };
      this.$store
        .dispatch(UPDATE_TAB_CELL_POSITION, payload)
        .then((response) => {
          this.$log.info(UPDATE_TAB_CELL_POSITION, payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(UPDATE_TAB_CELL_POSITION, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
  },
};
</script>

<style>
.site-btns {
  display: grid;
  padding-left: 10px;
}

.cell-container-a-tag, .edit-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell-container-condensed {
  padding: 25px;
  border-left: 0 !important;
  border-top: 0 !important;
}

.cell-container-grid {
  margin-right: 8px;
  padding: 8px;
}

@media (min-width: 0px) and (max-width: 280px) {
  .tab__selected {
    padding: 20px 38px !important;
  }

  .big-cells .public-user-cells-container {
    justify-content: left;
    margin-top: 10px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

    .cell-container-condensed {
      margin: 0;
      padding: 0 12.5px;

      .cell-container {
        margin: 8px auto;
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 389px) {

  .cell-container-grid {
    margin-right: 13px;
  }

  .big-cells .public-user-cells-container {
    justify-content: left;
    margin-top: 10px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

    .cell-container-condensed {
      margin: 0;
      padding: 3px 15px;

      .cell-container {
        margin: 10px auto;
      }
    }
  }
}

@media (min-width: 390px) and (max-width: 413px) {
  .cell-container-grid {
    margin-right: 23px;
  }

  .big-cells .public-user-cells-container {
    justify-content: left;
    margin-top: 10px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

    .cell-container-condensed {
      margin: 0;
      padding: 2px 20px;

      .cell-container {
        margin: 13px auto;
      }
    }
  }
}

@media (min-width: 412px) and (max-width: 429px) {
  .cell-container-grid {
    margin-right: 30px;
  }

  .big-cells .public-user-cells-container {
    justify-content: left;
    margin-top: 10px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

    .cell-container-condensed {
      margin: 0;
      padding: 2px 23.8px;

      .cell-container {
        margin: 18px auto;
      }
    }
  }
}

@media (min-width: 430px) and (max-width: 539px) {
  .cell-container-grid {
    margin-right: 30px;
  }

  .big-cells .public-user-cells-container {
    justify-content: left;
    margin-top: 10px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

    .cell-container-condensed {
      margin: 0;
      padding: 2px 26.8px;

      .cell-container {
        margin: 18px auto;
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 760px) {
  .cell-container-grid {
    margin-right: 30px;
  }

  .big-cells .public-user-cells-container {
    justify-content: left;
    margin-top: 10px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

    .cell-container-condensed {
      margin: 0;
      padding: 2px 24.3px;

      .cell-container {
        margin: 18px auto;
      }
    }
  }
}

@media (min-width: 470px) and (max-width: 639px) {
  .cell-container-condensed {
    .cell-container {
      margin: 25px auto !important;
    }
  }

  .cell-container-grid {
    margin-right: 27px;
  }
}

@media (min-width: 640px) and (max-width: 760px) {
  .cell-container-condensed {
    .cell-container {
      margin: 30px auto !important;
    }
  }
}

@media (min-width: 769px) and (max-width: 780px) {
  .cell-container-grid {
    margin-right: 2px;
    padding: 3px;
  }
}

@media (min-width: 781px) {
  .cell-container-grid {
    margin-right: 17px;
    padding: 10px;
  }
}

.cell-margin-bottom-condensed {
  display: inline-flex;
}

.cell-edit-on-hover {
  position: absolute;
  width: 20px;
  height: 22px;
  transform: translate3d(105px, 0, 0);
  cursor: pointer;
  display: none;
  z-index: 10;
  align-items: center;
  justify-content: center;
}

.cell-container-parent:hover .cell-edit-on-hover {
  display:flex;
}

.cell-margin-bottom-grid {
  margin-bottom: 1rem;
  display: inline-flex;
}

.logo {
  border: 1px solid #D9D9D9;
  padding: 10px;
  margin-right: 15px;
}

.list-container {
  display: flex;
  padding-bottom: 10px;
}

.icon-padding {
  padding: 8px 8px 6px 9px !important;
}

.cell-title {
  cursor: pointer;
  text-decoration: unset !important;
  font-size: 16px;
  color: black !important;
}

.link.alt {
  font-size: 14px;
  word-break: break-all;
  color: #0f439e;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

}

.public-user-cells-condensed {
  display: flex;
  flex-wrap: wrap;
}

.public-user-cells-condensed .md-layout-item {
  flex: unset;
}

.plus-icon-cell {
  background-size: 17px;
}

.plus-icon-cell:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.weekly-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
