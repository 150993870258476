import {
  ADMIN_MODE,
  SET_TAB_CELL_EDITABLE_ID_ADMIN,
  RESET_TAB_CELL_EDITABLE_ID_ADMIN,
} from '@/store/actions.type';

import {
  SET_TAB_CELL_EDITABLE_ID,
  RESET_TAB_CELL_EDITABLE_ID,
  SET_SHARE_TAB_MODAL_VALUE,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  data: () => ({
    cellsViewTypes: {
      grid: 0,
      list: 1,
      condensed: 2,
    },
  }),
  computed: {
    isGrid() {
      return this.currentCellsView === this.cellsViewTypes.grid;
    },
    isCondensed() {
      return this.currentCellsView === this.cellsViewTypes.condensed;
    },
    isList() {
      return this.currentCellsView === this.cellsViewTypes.list;
    },
  },
  methods: {
    changeView() {
      /* eslint-disable-next-line no-plusplus */
      this.currentCellsView = (++this.currentCellsView % 3);
    },
    showCellModal(value) {
      if (this.$store.getters.user) {
        if (!this.isDifferentUser) {
          this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false); // to avoid show the shard modal if you closed it by click outside
          this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID);
          this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID_ADMIN);
          if (this.adminMode) {
            this.$store.dispatch(ADMIN_MODE, true);
            this.$store.dispatch(SET_TAB_CELL_EDITABLE_ID_ADMIN, value.id);
          } else {
            this.$store.dispatch(ADMIN_MODE, false);
            this.$store.dispatch(SET_TAB_CELL_EDITABLE_ID, value.id); // tab id
          }
        } else {
          this.$toasted.show('Login to this account in order to add/edit a site.', {
            type: 'warning',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        }
      } else {
        this.login();
      }
    },
    editButtons(modalType) {
      if (this.$store.getters.user) {
        if (!this.isDifferentUser) {
          // Make Edit button visible
          if (modalType === 'cell') {
            this.editableCell = !this.editableCell;
          }
        } else {
          this.$toasted.show('Login to this account in order to edit.', {
            type: 'warning',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        }
      } else {
        this.login();
      }
    },
  },
};
