<template lang="html">
  <div>

    <div class="main-buttons-mobile">
      <md-button data-v-step="3"
        v-if="((publicUsername !== null) && (!publicUsername || adminMode)) || (!isDifferentUser)"
        class='md-icon-button tab-buttons' @click="showTabModalForAddNewTab">
        <md-icon> <i class="far fa-plus-square tabs-menu-btn-icon "></i></md-icon>
      </md-button>

      <md-button data-v-step="5" v-show="userTabs.length > 0"
        v-if="((publicUsername !== null) && (!publicUsername || adminMode)) || (!isDifferentUser)"
        class='md-icon-button tab-buttons' @click="showTabModalForEditTab">
        <md-icon><i class="fas fa-pen"></i></md-icon>
      </md-button>

      <regenerate-sprite-btn
        v-if="userTabs.length > 0 && (((publicUsername !== null) && (!publicUsername)) || (!isDifferentUser && !adminMode))"
        componentView="icon" :userId="userId"></regenerate-sprite-btn>

      <md-button v-if="!this.isPrivate && userTabs.length > 0" class='md-icon-button tab-buttons' @click="showShareModal">
        <md-icon><i class="fas fa-share"></i></md-icon>
      </md-button>

      <md-button title="Change sites layout" class="md-icon-button tab-buttons" @click="changeView()">
        <md-icon v-if="this.isGrid"><i class="fas fa-th-large"></i></md-icon>
        <md-icon v-if="this.isList"><i class="fas fa-list"></i></md-icon>
        <md-icon v-if="this.isCondensed"><i class="fa fa-square-o"></i></md-icon>
      </md-button>

      <p v-show="this.selectedTabDescription" class="description-section-mobile">
          {{ this.selectedTabDescription }}
      </p>

    </div>

    <div v-if="userTabs.length > 0" class="mobile-tab">
      <ul class='tabs__header'>
        <li v-for='(tab, index) in userTabs' :id="tab.id" :key='tab.id' :position="tab.position" :class='{
          "tab__selected": (index === selectedIndex), "visibleForAllMobile": (hideLi == 1), "hidden": (hideLi == 0),
          "hidden-without-slot": (!tab.position && tab.position !== 0)
        }' :title="tab.description" data-v-step="2">
          <div class="tab-header">
            <div @click='selectTab(index, tab.id)'>
              <div class="cell-container draggable edit-cell edit-tab"
                v-show="editableTab && tab.id && (tab.position || tab.position === 0)">
                <i class="far fa-edit"></i>
              </div>
              {{ tab.name }}
              <transition name="fade">
                <i class="fas fa-chevron-up" v-if="index === selectedIndex"></i>
                <i class="fas fa-chevron-down" v-if="index !== selectedIndex"></i>
              </transition>
            </div>
            <transition name="slide-fade">
              <div v-if="index === selectedIndex" class="button-container-edit">
                <md-button class="md-icon-button tab-buttons main-buttons ripple" @click="editCell" data-v-step="5">
                  <md-icon><i class="fas fa-edit"></i></md-icon>
                </md-button>
              </div>
            </transition>
          </div>
          <transition name="fade">
            <tabCell v-if="index === selectedIndex" :isDifferentUser="isDifferentUser"
              :currentCellsView="currentCellsView" :selectedTabObj="selectedTabObj" :editableCell="!editableCell"
              :publicUsername="publicUsername" :adminMode="adminMode" :mobileTabCell="true">
            </tabCell>
          </transition>
        </li>
        <li title="Show More" id="show-more" @click="showMore()"
          v-show="hideLi == 0 && !editableTab && userTabs.length > 6">
          More <i class="fas fa-caret-left"></i></li>
        <li title="Show Less" id="show-less" @click="showLess()"
          v-show="hideLi == 1 && !editableTab && userTabs.length > 6">
          Less <i class="fas fa-caret-down"></i></li>
        <br>
      </ul>
    </div>

    <TabModal v-if="!publicUsername || adminMode || !isDifferentUser" :adminMode="adminMode"
      :position="lastElPosition.position" :editableTab="editableTab" :currentEditTabObj="currentEditTabObj"
      v-show="isModalVisibleTab" :isModalVisibleTab="isModalVisibleTab" @close="close" :tab="userTabObj" :tabId="tabId"
      :currentTabsNumber="userTabs.length">
    </TabModal>
  </div>
</template>

<script>
import tabActionsMixin from '@/mixins/tabActionsMixin';
import TabModal from '@/components/modals/TabModal.vue';
import tabCell from '@/components/tabs-template/TabCell.vue';
import LocalStorageService from '@/services/localStorage.service';
import RegenerateSpriteBtn from '@/components/common/user/RegenerateSpriteBtn.vue';
import cellLayoutMixin from '@/mixins/cellLayoutMixin';
import {
  SET_SELECTED_TABA, SET_EDITABLE_TAB_MODAL_VALUE, SET_SELECTED_TAB_INDEX, SET_TAB_MODAL_VALUE,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Tabs',
  mixins: [tabActionsMixin, cellLayoutMixin],
  components: {
    TabModal,
    tabCell,
    RegenerateSpriteBtn,
  },
  props: {
    editableTab: Boolean,
    publicUsername: Object,
    adminMode: Boolean,
    isDifferentUser: Boolean,
    isPrivate: Boolean,
  },
  data() {
    return {
      selectedIndex: this.$store.getters.selectedTabIndex,
      currentEditTabObj: null,
      selectedTabObj: null, // selected tab to pass to tab cell
      tabs: [],
      userTabObj: null,
      tabId: null,
      addButton: 1,
      addSecondButton: 0,
      publicPath: process.env.BASE_URL,
      hideLi: LocalStorageService.getShowMoreTabs(),
      lastElPosition: 0, // find the cell last position for the new cell
      userTab: {},
      editableCell: false,
      selectedTabDescription: null,
      currentCellsView: 2,
    };
  },
  computed: {
    isModalVisibleTab() {
      return this.$store.getters.isModalVisibleTab
        || this.$store.getters.isEditableModalVisibleTab;
    },
    userTabs() {
      // check if the logged user is not the same with the user from url
      if (this.adminMode) {
        return this.$store.getters.userData.userTabs;
      }
      // myfaves page
      if (this.publicUsername) {
        if (this.$store.getters.user && this.$store.getters.user.id === this.publicUsername.id) {
          // myfaves page but for the current logged in user
          return this.$store.getters.userTabs;
        }
        // display the tabs for the user from url (public user) && hide private tabs
        // eslint-disable-next-line array-callback-return
        return this.publicUsername.userTabs.filter(tab => !tab.privatetab);
      }
      // homepage
      if (this.publicUsername === undefined) {
        // auth user usertabs
        return this.$store.getters.userTabs;
      }
      // /username - or for any other case, no tabs right now.
      return [];
    },
  },
  mounted() {
    let i = this.$store.getters.selectedTabIndex;
    // -1 means wrong tab id in the url, so fetch from the index from the state
    i = (i === -1) ? this.$store.getters.selectedTabIndex : i;
    this.selectTab(i);
    this.setUserHasTabs(this.userTabs.length > 0);
    // Find that highest position of the tabs
    if (this.userTabs.length > 0) {
      this.lastElPosition = this.userTabs.reduce((prev, current) => {
        if (+current.position > +prev.position) {
          return current;
        }
        return prev;
      });
    }
  },
  methods: {
    getIndexOfTabByName(tabs, tabName) {
      return tabs.findIndex(tab => tab.name === tabName);
    },
    selectTab(i) {
      const payload = { selectedTabIndex: i };
      this.$store.dispatch(SET_SELECTED_TAB_INDEX, payload);
      this.selectedIndex = i;
      // loop over all the tabs
      this.userTabs.forEach((tab, index) => {
        // eslint-disable-next-line no-param-reassign
        tab.isActive = (index === i);
      });

      this.setCurrentEditTabObj(this.userTabs[i]);
      this.selectedTabObj = this.userTabs[i];
      this.$store.dispatch(SET_SELECTED_TABA, this.userTabs[i]);

      // close the premium modal once we open it, then go outside of it
      // in this case, the open stats will be true so we must return it to false (default)
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
    },
    editCell() {
      this.editableCell = !this.editableCell;
    },
    showMore() {
      this.hideLi = 1;
      this.addButton = 0;
      this.addSecondButton = 1;
      LocalStorageService.setShowMoreTabs(true);
    },
    showLess() {
      this.hideLi = 0;
      this.addButton = 1;
      this.addSecondButton = 0;
      LocalStorageService.setShowMoreTabs(false);
    },
  },
  watch: {
    publicUsername: {
      handler(val) {
        if (val) {
          if (this.userTabs.length > 0) {
            const tabName = this.$route.query?.tab;
            let idx = this.getIndexOfTabByName(this.userTabs, tabName);
            idx = (idx !== -1) ? idx : 0;
            this.selectTab(idx);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/tab-mobile.scss';
</style>
