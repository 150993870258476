<template lang="html">
  <div>
    <div v-show="this.selectedTabDescription" class="description-section">
      <p>
        {{ this.selectedTabDescription }}
      </p>
    </div>
    <div class="user-tabs-section">
      <div class="tabs_container">
        <div class="main-buttons tabs-btns">

          <md-button v-show="hasTabs > 0" class="toggle-tabs-menu md-icon-button tab-buttons" @click="sortTabs()">
            <md-icon title="Sort tabs">
              <i
                :class="[this.currentSort === this.sortTypes.original ? 'fas fa-sort' : this.currentSort === this.sortTypes.asc ? 'fas fa-chevron-up' : 'fas fa-chevron-down']"></i>
            </md-icon>
          </md-button>

          <md-button data-v-step="3" title="Add new tab"
            v-if="((publicUsername !== null) && (!publicUsername || adminMode)) || (!isDifferentUser)"
            class='md-icon-button tab-buttons' @click="showTabModalForAddNewTab">
            <md-icon> <i class="far fa-plus-square tabs-menu-btn-icon "></i></md-icon>
          </md-button>

          <md-button data-v-step="5" v-show="hasTabs > 0 && !isWeekly" title="Edit tab"
            v-if="((publicUsername !== null) && (!publicUsername || adminMode)) || (!isDifferentUser)"
            class='md-icon-button tab-buttons' @click="showTabModalForEditTab">
            <md-icon><i class="fas fa-pen"></i></md-icon>
          </md-button>

          <regenerate-sprite-btn title="Regenerate tab logos" v-show="!isWeekly"
            v-if="hasTabs > 0 && (((publicUsername !== null) && (!publicUsername)) || (!isDifferentUser && !adminMode))"
            componentView="icon" :userId="userId"></regenerate-sprite-btn>

          <md-button title="Share tab link" v-show="!isWeekly" v-if="!this.isPrivate && hasTabs > 0" class='md-icon-button tab-buttons' @click="showShareModal">
            <md-icon><i class="fas fa-share"></i></md-icon>
          </md-button>
        </div>
        <ul class='tabs__header' v-if="hasTabs > 0"
          :class="isCondensed ? 'tabs__header-condensed' : 'tabs__header-non-condensed'"
          :style="dynaminHeightForTabsList">
          <li v-for='(tab, index) in this.userTabsAttr.slice(0, 6)' :key='tab.id' :id="'tab-' + tab.id"
            :position="tab.position" :class='{
              "tab__selected": (index == selectedIndex), "visibleForAll": (hideLi == 1), "hidden": (hideLi == 0),
              "hidden-without-slot": (!tab.position && tab.position !== 0)
            }' @click='selectTab(index)' :title="tab.description" data-v-step="2">
            {{ tab.name }}
          </li>
          <li title="Show More" id="show-more" @click="showMore()" v-show="hideLi == 0 && tabslength > 6">
            More <i class="fas fa-caret-left"></i></li>
          <li title="Show Less" id="show-less" @click="showLess()" v-show="hideLi == 1 && tabslength > 6">
            Less <i class="fas fa-caret-down"></i></li>
          <li v-for='(tab, index) in this.userTabsAttr.slice(6, tabslength)' :key='tab.id'
            :id="'tab-' + tab.id" :position="tab.position" :class='{
              "tab__selected": ((index + 6) == selectedIndex), "visibleForAll": (hideLi == 1), "hidden": (hideLi == 0),
              "hidden-without-slot": (!tab.position && tab.position !== 0)
            }' @click='selectTab(index + 6)' :title="tab.description">
            {{ tab.name }}
          </li>
        </ul>
        <ul v-else class='tabs__header'>
          <p>
            This User hasn't any public tabs yet.
          </p>
        </ul>
      </div>

      <TabCell :editableCell="editableCell" :currentCellsView="currentCellsView" :selectedTabObj="selectedTabObj"
        @isCondensedMode="isCondensedMode" :publicUsername="publicUsername" :isDifferentUser="isDifferentUser"
        :adminMode="adminMode">
      </TabCell>
    </div>

    <TabModal v-if="!publicUsername || adminMode || !isDifferentUser" :position="lastElPosition.position"
      :editableTab="editableTab" :adminMode="adminMode" :currentEditTabObj="currentEditTabObj" @close="close"
      :tab="userTabObj" :tabId="tabId" :currentTabsNumber="tabslength"
      :isModalVisibleTab="isModalVisibleTab" v-show="isModalVisibleTab">
    </TabModal>
  </div>
</template>

<script>
import renderedUserTabs from '@/mixins/renderedUserTabs';
import tabActionsMixin from '@/mixins/tabActionsMixin';
import TabModal from '@/components/modals/TabModal.vue';
import TabCell from '@/components/tabs-template/TabCell.vue';
import LocalStorageService from '@/services/localStorage.service';
import RegenerateSpriteBtn from '@/components/common/user/RegenerateSpriteBtn.vue';
import {
  SET_SELECTED_TABA, SET_EDITABLE_TAB_MODAL_VALUE, SET_SELECTED_TAB_INDEX, SET_TAB_MODAL_VALUE,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Tabs',
  mixins: [tabActionsMixin, renderedUserTabs],
  components: {
    TabCell,
    TabModal,
    RegenerateSpriteBtn,
  },
  props: {
    editableTab: Boolean,
    publicUsername: Object,
    adminMode: Boolean,
    isDifferentUser: Boolean,
    newTab: Boolean,
    isPrivate: Boolean,
    currentCellsView: Number,
    editableCell: Boolean,
  },
  data() {
    return {
      selectedIndex: this.$store.getters.selectedTabIndex,
      // Tab variables
      currentEditTabObj: null,
      selectedTabObj: null, // selected tab to pass to tab cell
      tabs: [],
      userTabObj: null,
      tabId: null,
      addButton: 1,
      addSecondButton: 0,
      publicPath: process.env.BASE_URL,
      hideLi: LocalStorageService.getShowMoreTabs(),
      lastElPosition: 0, // find the cell last position for the new cell
      userTab: {},
      isCondensed: true,
      selectedTabDescription: null,
      sortTypes: {
        original: 0,
        asc: 1,
        desc: 2,
      },
      currentSort: 0,
    };
  },
  computed: {
    userTabs() {
      // we need to watch it in order to update the state when we add, edit, remove tab or cell
      return this.$store.getters.userTabs ?? [];
    },
    selectedTabNameBySearch() {
      return this.$store.getters.selectedTabNameBySearch;
    },
    isModalVisibleTab() {
      return this.$store.getters.isModalVisibleTab
        || this.$store.getters.isEditableModalVisibleTab;
    },
    dynaminHeightForTabsList() {
      const { tabCells } = this.selectedTabObj ? this.selectedTabObj : [];
      const tabCellscellRows = tabCells ? tabCells?.length / 6 : 2;
      let maxHeight = (tabCellscellRows) * 80;
      maxHeight = maxHeight < 400 ? 400 : maxHeight;

      return `max-height: ${maxHeight}px`;
    },
    hasTabs() {
      return this.userTabsAttr ? this.userTabsAttr.length > 0 : false;
    },
    tabslength() {
      return this.userTabsAttr ? this.userTabsAttr.length : 0;
    },
    isWeekly() {
      return this.$store.getters.selectedTab?.id === -1;
    },
  },
  mounted() {
    this.setUserTabs();
    let i = this.$store.getters.selectedTabIndex;
    // -1 means wrong tab id in the url, so fetch from the index from the state
    i = (i === -1) ? this.$store.getters.selectedTabIndex : i;
    this.selectTab(i);
    // Find that highest position of the tabs
    if (this.hasTabs) {
      this.lastElPosition = this.userTabsAttr.reduce((prev, current) => {
        if (+current.position > +prev.position) {
          return current;
        }
        return prev;
      });
    }
  },
  methods: {
    selectTab(i) {
      const payload = { selectedTabIndex: i };
      this.$store.dispatch(SET_SELECTED_TAB_INDEX, payload);
      this.selectedIndex = i;
      // loop over all the tabs
      if (this.userTabsAttr) {
        this.userTabsAttr.forEach((tab, index) => {
          // eslint-disable-next-line no-param-reassign
          tab.isActive = (index === i);
        });

        this.setCurrentEditTabObj(this.userTabsAttr[i]);
        this.selectedTabObj = this.userTabsAttr[i];
        this.$store.dispatch(SET_SELECTED_TABA, this.userTabsAttr[i]);
      }

      // close the premium modal once we open it, then go outside of it
      // in this case, the open stats will be true so we must return it to false (default)
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
    },
    isCondensedMode(val) {
      this.isCondensed = val;
    },
    getIndexOfTabByName(tabs, tabName) {
      return tabs.findIndex(tab => tab.name === tabName);
    },
    showMore() {
      this.hideLi = 1;
      this.addButton = 0;
      this.addSecondButton = 1;
      LocalStorageService.setShowMoreTabs(true);
    },
    showLess() {
      this.hideLi = 0;
      this.addButton = 1;
      this.addSecondButton = 0;
      LocalStorageService.setShowMoreTabs(false);
    },
    sortTabs() {
      const sortedAsc = [...this.originalTabs];
      const sortedDesc = [...this.originalTabs];

      if (this.sortTypes.original === this.currentSort) {
        this.currentSort = this.sortTypes.asc;
        this.userTabsAttr = sortedAsc.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      } else if (this.sortTypes.asc === this.currentSort) {
        this.currentSort = this.sortTypes.desc;
        this.userTabsAttr = sortedDesc.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
      } else if (this.sortTypes.desc === this.currentSort) {
        this.currentSort = this.sortTypes.original;
        this.userTabsAttr = this.originalTabs;
      }
      this.selectTab(this.selectedIndex);
    },
  },
  watch: {
    publicUsername: {
      handler(val) {
        if (val) {
          this.setUserTabs();
          if (this.userTabsAttr?.length > 0) {
            const tabName = this.$route.query?.tab;
            let idx = this.getIndexOfTabByName(this.userTabsAttr, tabName);
            idx = (idx !== -1) ? idx : this.selectedIndex;
            idx = (idx > this.userTabsAttr?.length - 1) ? 0 : idx;

            this.selectTab(idx);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    userTabs: {
      handler() {
        this.setUserTabs();
        this.selectTab(this.selectedIndex);
      },
      deep: true,
      immediate: true,
    },
    selectedTabNameBySearch: {
      handler(val) {
        const index = this.userTabsAttr?.findIndex(tab => (tab.name).toLowerCase() === val.toLowerCase());
        if (index && index !== -1) {
          this.selectTab(index);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/user-top-tabs.scss';

.tabs__header-condensed {
  border-right: 0;
}

.tabs__header-non-condensed {
  border-right: 1px solid #D9D9D9;
}
</style>
