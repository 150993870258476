<template>
    <md-button v-if="isBtn" type="button" class="md-raised download-button re-generate-btn" @click="regenerateSprite">
      Re Generate Sprite
    </md-button>
    <md-button v-else-if="isIcon" type="button" class="md-icon-button tab-buttons" @click="regenerateSprite">
      <md-icon ><i class="fas fa-sync"></i></md-icon>
    </md-button>
</template>

<script>
import { REGENERATE_USER_SPRITE } from '@/store/modules/user/userActions.type';

export default {
  name: 'RegenerateSpriteBtn',
  props: {
    userId: Number,
    componentView: String,
  },
  computed: {
    isIcon() {
      return this.componentView === 'icon';
    },
    isBtn() {
      return this.componentView === 'btn';
    },
    selectedTabObject() {
      return this.$store.getters.selectedTab;
    },
  },
  methods: {
    regenerateSprite() {
      const payload = {
        id: this.userId,
        tabId: this.selectedTabObject.id,
        adminMode: this.isBtn,
      };
      this.$store.dispatch(REGENERATE_USER_SPRITE, payload)
        .then((response) => {
          this.$log.info(REGENERATE_USER_SPRITE, payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(REGENERATE_USER_SPRITE, error);
          this.$toasted.show(error, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
  },
};
</script>

<style scoped>
.re-generate-btn {
  height: 2.3rem;
}
</style>
