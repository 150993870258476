<template>
  <div class="md-layout md-alignment-center-center spinner-container" v-if="isLoading">
    <div id="loadingContainer" class="md-layout-item">
      <md-progress-bar class="md-accent" md-mode="indeterminate" />
    </div>
  </div>

  <div class="user-tabs-container" v-else>
    <md-card id="user-top-tabs-card">
      <md-card-content>
        <div class="heading-tabs test">
          <div class="md-layout md-alignment-center-left">
            <div class="md-layout-item md-size-100">
              <tabsDesktop :editableCell="editableCell" :currentCellsView="currentCellsView" :isPrivate="isPrivate"
                :showMenu="showMenu" v-if="!isMobile" :editableTab="editableTab" :publicUsername="publicUsername"
                :adminMode="adminMode" :isDifferentUser="isDifferentUser" @setUserHasTabs="setUserHasTabs" @close="close">
              </tabsDesktop>

              <tabsMobile v-else :editableTab="editableTab" :publicUsername="publicUsername"
                :isPrivate="isPrivate" :adminMode="adminMode" @setUserHasTabs="setUserHasTabs"
                :isDifferentUser="isDifferentUser" @close="close"></tabsMobile>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div class="site-btns main-buttons main-buttons-tab" v-if="!isMobile && !isLoading && (isAuthenticated || isMyFaves) ">
      <md-button v-show="!isWeeklyTab" title="Add new site" @click="showCellModal(selectedTabObj)" data-v-step="8" class="md-icon-button tab-buttons">
        <md-icon><i class="fas fa-plus-square"></i></md-icon>
      </md-button>
      <md-button v-show="!isWeeklyTab" title="Edit site" data-v-step="7" class="md-icon-button tab-buttons" @click="editButtons('cell')">
        <md-icon><i class="fas fa-pen"></i></md-icon>
      </md-button>
      <md-button title="Change sites layout" class="md-icon-button tab-buttons" @click="changeView()">
        <md-icon v-if="this.isGrid"><i class="fas fa-th-large"></i></md-icon>
        <md-icon v-if="this.isList"><i class="fas fa-list"></i></md-icon>
        <md-icon v-if="this.isCondensed"><i class="fa fa-square-o"></i></md-icon>
      </md-button>
      <md-button v-show="this.canZoomIn" title="Zoom in" class="md-icon-button tab-buttons" @click="zoomIn()">
        <md-icon><i class="fas fa-search-plus"></i></md-icon>
      </md-button>
      <md-button v-show="this.canZoomOut" title="Zoom Out" class="md-icon-button tab-buttons" @click="zoomOut()">
        <md-icon><i class="fas fa-search-minus"></i></md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>
import TabsDesktop from '@/components/tabs-template/Tabs.vue';
import TabsMobile from '@/components/tabs-template/TabsMobile.vue';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';
import weeklyTabMixin from '@/mixins/weeklyTabMixin';
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import landingMixin from '@/mixins/landingMixin';
import loaderMixin from '@/mixins/loaderMixin';
import cellLayoutMixin from '@/mixins/cellLayoutMixin';
import { SET_TAB_MODAL_VALUE, SET_EDITABLE_TAB_MODAL_VALUE } from '../../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'UserTopTabs',
  components: {
    TabsDesktop,
    TabsMobile,
  },
  mixins: [
    loggedInUserMixin,
    smallDevicesCheckMixin,
    loaderMixin,
    cellLayoutMixin,
    landingMixin,
    weeklyTabMixin,
  ],
  props: {
    publicUsername: {
      type: Object,
    },
    adminMode: {
      type: Boolean,
    },
    isDifferentUser: {
      type: Boolean,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      windowWidth: null,
      showMenu: true,
      userHasTabs: false,
      selectedTabDescription: null,
      currentCellsView: 2,
      editableCell: true,
      canZoomOut: true,
      canZoomIn: true,
    };
  },
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  computed: {
    isMyFaves() {
      return this.currentRouteName === 'myFaves';
    },
    editableTab() {
      return this.$store.getters.isEditableModalVisibleTab;
    },
    isPrivate() {
      const tab = this.selectedTabObj;

      return !!tab?.privatetab;
    },
    selectedTabObj() {
      return this.$store.getters.selectedTab;
    },
  },
  methods: {
    showTabModalForEditTab() {
      this.close();
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, true);
    },
    showTabModalForAddNewTab() {
      this.close();
      this.$store.dispatch(SET_TAB_MODAL_VALUE, true);
    },
    toggleMenu() {
      this.close();
      this.showMenu = !this.showMenu;
    },
    close() {
      // cuz state isnot reset to false if you close it by clicking outside
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
    },
    setUserHasTabs(value) {
      this.userHasTabs = value;
    },
    zoomIn() {
      const currZoom = document.body.style.zoom || 1;
      document.body.style.zoom = parseFloat(currZoom) + 0.1;
      this.canZoomIn = document.body.getBoundingClientRect().width > 1150;
      this.canZoomOut = document.body.getBoundingClientRect().width < 1400;
    },
    zoomOut() {
      const currZoom = document.body.style.zoom || 1;
      document.body.style.zoom = parseFloat(currZoom) - 0.1;
      this.canZoomIn = document.body.getBoundingClientRect().width > 1150;
      this.canZoomOut = document.body.getBoundingClientRect().width < 1400;
    },
  },
  watch: {
    publicUsername: {
      handler(val) {
        if (val) {
          this.setUserHasTabs(val.userTabsCount > 0);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/site-autosuggest.scss';

#user-top-tabs-card .md-card-content-mobile {
  padding: 0;
}

#user-top-tabs-card .md-card-content {
  padding: 0;
}
</style>
